import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/poradnik/#katar"> &gt; Poradnik(Katar)</a>
            <a
              href="/poradnik/katar/katar_alergiczny_-_poznaj_i_zapobiegaj/"
              className="sel"
            >
              {" "}
              &gt; Katar alergiczny - poznaj i zapobiegaj
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="main_photo">
                <img src="/web/kcfinder/uploads/files/shutterstock_1206612040.jpg" />
              </div>
            </div>
            <div className="col2">
              <h1>Katar alergiczny - poznaj i zapobiegaj</h1>
              <div className="wysiwyg">
                <p className="p1">
                  <span className="s1">
                    <b>
                      Wiosną rozkwitają drzewa, krzewy i inne rośliny. To
                      wymarzony czas, by odpocząć na świeżym powietrzu,
                      zorganizować piknik na łące czy wybrać się na spacer do
                      lasu. Niestety dla alergików to bardzo trudny okres. Na
                      nieżyt nosa wywołany pyłkami traw, drzew i chwastów cierpi
                      prawie 25 % Polaków
                    </b>
                  </span>
                  <span className="s2">
                    <b>
                      <sup>1</sup>
                    </b>
                  </span>
                  <span className="s1">
                    <b> (około 9 milionów osób).&nbsp;</b>
                  </span>
                </p>
                <p className="p1">&nbsp;</p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Katar, kichanie i drapanie w gardle to częste objawy mylone
                    z{" "}
                    <a href="/slowniczek/#przeziebienie" target="_blank">
                      przeziębieniem.
                    </a>{" "}
                    Choć pojawiają się sezonowo i występują rokrocznie, wiele
                    osób, u których{" "}
                    <a href="/slowniczek/#alergia" target="_blank">
                      alergia
                    </a>{" "}
                    pojawia się po raz pierwszy, nie zdaje sobie sprawy, że
                    przyczyną trudności w oddychaniu, łzawienia oczu i nieżytu
                    nosa są alergeny wziewne.
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Maj to prawdziwa eksplozja pyłków. W tym samym czasie kwitną
                    drzewa (w szczególności brzoza) i zaczynają pylić trawy
                    (głównie bylica, kostrzewa łąkowa, kupkówka pospolita) –
                    rośliny, które najczęściej uczulają alergików. Wywołane
                    przez nie objawy (np. katar sienny, ucisk w okolicy nosa,
                    pieczenie spojówek i łzawienie oczu, kłopoty ze snem,
                    swędzenie w nosie, bóle głowy, zalegająca{" "}
                    <a href="/slowniczek/#wydzielina_z_nosa" target="_blank">
                      wydzielina)
                    </a>{" "}
                    utrudniają wykonywanie codziennych czynności, takich jak:
                    pochylanie się, czytanie, korzystanie z komputera czy
                    uprawianie sportów. Dodatkowo powodują też osłabienie i
                    rozdrażnienie. Wśród pyłków podrażniających błonę śluzową
                    nosa znajdują się m.in. leszczyna, olcha, pokrzywka, babka
                    lancetowata i żyto.
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Katar sienny to reakcja obronna organizmu na{" "}
                    <a href="/slowniczek/#antygen" target="_blank">
                      antygeny,
                    </a>{" "}
                    które znajdują się w pyłkach roślin, drzew, krzewów i traw.
                    Ta przypadłość dopada ponad 20% Polaków. Zwykle objawy
                    pierwszej alergii pojawiają się w okresie dzieciństwa lub
                    dorastania (u dzieci i młodzieży poniżej dwudziestego roku
                    życia). Alergiczne{" "}
                    <a
                      href="/slowniczek/#zapalenia_blony_sluzowej_nosa"
                      target="_blank"
                    >
                      zapalenie błony śluzowej nosa
                    </a>
                    &nbsp;pojawia się w wyniku kontaktu z substancją, którą{" "}
                    <a href="/slowniczek/#uklad_immunologiczny" target="_blank">
                      układ immunologiczny
                    </a>{" "}
                    błędnie rozpoznał jako zagrożenie i w następstwie tego
                    rozpoczął produkcję przeciwciał. Każdy kolejny kontakt z
                    taką substancją powoduje wydzielanie histaminy i silne
                    dolegliwości (dostrzegalne natychmiast po kontakcie z
                    alergenami, np. wodnista wydzielina z nosa, łzawienie
                    oczu).&nbsp;
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <img
                    alt="Katar sienny - jak łagodzić objawy alergii? SUDAFED®"
                    src="/web/kcfinder/uploads/images/shutterstock_122248285.jpg"
                    style={{
                      float: "left",
                      margin: "5px 10px 10px 0px",
                      border: "2px solid rgb(255, 255, 255)",
                      height: "320px",
                      width: "372px",
                    }}
                  />
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Jeśli ma się kontakt z alergenami, trudno jest zapobiec
                    objawom alergii, dlatego powinniśmy wiedzieć, jak sobie z
                    nimi radzić. Podczas alergii pacjenci często skarżą się na
                    uczucie zablokowanego nosa, dlatego warto zastosować
                    produkty obkurczające błonę śluzową nosa np.{" "}
                    <a href="/produkty/sudafed_tabletki/" target="_blank">
                      Sudafed<sup>®</sup> Tabletki{" "}
                    </a>
                    &nbsp;(udrażniają górne drogi oddechowe, zmniejszają obrzęk
                    i łagodzą stan zapalny, jednocześnie elimunując uczucie
                    ucisku w głowie i zatokach<sup>1</sup>) oraz aerozole do
                    nosa{" "}
                    <a href="/produkty/sudafed_xylospray/" target="_blank">
                      Sudafed<sup>®</sup> XyloSpray
                    </a>{" "}
                    lub{" "}
                    <a href="/produkty/sudafed_xylospray_ha/" target="_blank">
                      Sudafed<sup>®</sup> XyloSpray HA
                    </a>{" "}
                    (ułatwiają odpływ zalegającej wydzieliny). Dodatkowo,
                    Sudafed<sup>®</sup> XyloSpray HA jako jedyny<sup>2</sup>{" "}
                    posiada aż trzy substancje nawilżające: glicerol, sorbitol i
                    kwas hialuronowy (w postaci soli sodowej) oraz nie zawiera
                    konserwantów, dzięki czemu nie podrażnia błony śluzowej nosa
                    i nie nasila objawów alergicznego nieżytu nosa,które mogą
                    być wywoływane przez konserwanty<sup>3</sup>. Wiosną, gdy
                    rośliny zaczynają pylić należy unikać alergenów. Powinniśmy
                    również pamiętać o częstym myciu twarzy, zachowaniu
                    czystości mieszkania i miejsca pracy, a nawet noszeniu
                    maseczek ochronnych.
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <div>
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    1 Dotyczy uczucia ucisku w zatokach spowodowanego
                    nagromadzoną wydzieliną i obrzękiem błony śluzowej nosa.
                  </span>
                </div>
                <div>
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    2 Wśród produktów zawierających Xylometazolinę/Oxymetazolinę
                    w postaci aerozolu do nosa, dopuszczonych do obrotu w Polsce
                    według Urzędowego Wykazu Produktów Leczniczych Dopuszczonych
                    do Obrotu na terytorium Rzeczypospolitej Polskiej, według
                    stanu na dzień 1 Lutego 2023 r.
                  </span>
                </div>
                <div>
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    3 Lebe E, Baka M, Yavaşoğlu A, Aktuğ H, Ateş U, Uyanikgil Y.
                    Effects of preservatives in nasal formulations on the
                    mucosal integrity: an electron microscopic study.
                    Pharmacology. 2004 Oct;72(2):113-20.
                  </span>
                </div>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    <span className="s1">Bibliografia:</span>
                  </span>
                </p>
                <ol className="ol1">
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    <span
                      style={{
                        "font-size": "11px",
                      }}
                    >
                      <span className="s1">
                        Jakimowicz-Klein Barbara,{" "}
                        <i>
                          Alergia u dzieci i dorosłych. Profilaktyka i skuteczne
                          metody leczenia
                        </i>
                        , Wydawnictwo Astrum, 2015.
                      </span>
                    </span>
                  </li>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    <span
                      style={{
                        "font-size": "11px",
                      }}
                    >
                      <span className="s1">
                        Wrotek Katarzyna, <i>Alergia</i>, Septem, 2006.
                      </span>
                    </span>
                  </li>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    <span
                      style={{
                        "font-size": "11px",
                      }}
                    >
                      <span className="s1">
                        Słowińska Anna,{" "}
                        <i>Alergie układu oddechowego – katar sienny</i>, [w:]
                        „Medycyna Rodzinna”, 2003, nr 2, s. 77-84.
                      </span>
                    </span>
                  </li>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    <span
                      style={{
                        "font-size": "11px",
                      }}
                    >
                      <span className="s1">
                        Rapiejko Piotr,{" "}
                        <i>Alergiczny nieżyt nosa – 42 pytania i odpowiedzi</i>,
                        Warszawa, 2014. &nbsp;
                      </span>
                    </span>
                  </li>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    <span
                      style={{
                        "font-size": "11px",
                      }}
                    >
                      <span className="s1">
                        Emeryk Andrzej,{" "}
                        <i>
                          Leki przeciwhistaminowe w chorobach alergicznych – co
                          jest ważne dla lekarza i pacjenta?,
                        </i>{" "}
                        [w:] „Alergologia”, 2009, nr 1.
                      </span>
                    </span>
                  </li>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    <span
                      style={{
                        "font-size": "11px",
                      }}
                    >
                      <span className="s1">
                        A. Samoliński, <i>Przewlekłe nieżyty nosa</i>, [w:]
                        „Alergia”, 2014, nr 3, s. 49-55.
                      </span>
                    </span>
                  </li>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    <span
                      style={{
                        "font-size": "11px",
                      }}
                    >
                      B. Samoliński i inni, Częstość występowania nieżytów nosa
                      w polskiej populacji na podstawie badania
                      EpidemiologiaChorób Alergicznych w Polsce (ECAP),
                      Otolaryngol Pol 2009; 63 (4): 324-330
                    </span>
                  </li>
                </ol>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
              </div>
              <div className="other_article">
                <p className="name">Podobne artykuły</p>
                <a
                  href="/poradnik/katar/jak_prawidlowo_oczyszczac_nos_podczas_kataru"
                  className="box"
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/artykuly/shutterstock_300834140.jpg")',
                    }}
                  />
                  <span className="title">
                    Jak prawidłowo oczyszczać nos podczas kataru?
                  </span>
                </a>
                <a href="/poradnik/katar/poznaj_rodzaje_kataru" className="box">
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/artykuly/shutterstock_388715146.jpg")',
                    }}
                  />
                  <span className="title">Poznaj rodzaje kataru</span>
                </a>
                <a
                  href="/poradnik/katar/podejmij_szybka_walke_z_katarem"
                  className="box"
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/shutterstock_152527238.jpg")',
                    }}
                  />
                  <span className="title">Podejmij szybką walkę z katarem</span>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px",
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a
                    id="ot-sdk-btn"
                    className="ot-sdk-show-settings"
                    rel="nofollow"
                  >
                    {" "}
                    Ustawienia plików Cookie{" "}
                  </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty. 2023
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
